<template>
    <b-card title="Altres escriptures" border-variant="primary" class="text-left mt-2">
        <b-col lg="12" md="12" sm="12">
            <b-row v-for="(concept_protocol_record, index) in trading.concepts_protocols"
                   :key="concept_protocol_record.id">
                <b-col sm="2">
                    <b-form-group label="Concepte" label-for="document">
                        <b-form-input v-model="concept_protocol_record.concept" placeholder="Concepte"/>
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label="Escriptura" label-for="document">
                        <b-form-file
                            v-model="concept_protocol_record.scripture_document_file"
                            placeholder="Seleccionar"
                            drop-placeholder="Seleccionar"
                            browse-text="Seleccionar"
                        />
                    </b-form-group>
                </b-col>
                <b-col v-if="concept_protocol_record.scripture_document" class="mr-2 ml-0">
                    <a :href="baseUrl+concept_protocol_record.scripture_document" target="_blank">
                        <feather-icon
                            icon="EyeIcon"
                            class="cursor-pointer mt-0 mt-md-2"
                            size="25"
                        />
                    </a>

                </b-col>
                <b-col sm="2">
                    <b-form-group label="Protocol" label-for="document">
                        <b-form-input v-model="concept_protocol_record.protocol" placeholder="Protocol"/>
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label="Minuta notari" label-for="document">
                        <b-form-file
                            v-model="concept_protocol_record.protocol_document_file"
                            placeholder="Seleccionar"
                            drop-placeholder="Seleccionar"
                            browse-text="Seleccionar"
                        />
                    </b-form-group>
                </b-col>
                <b-col v-if="concept_protocol_record.protocol_document" class="mr-2 ml-0">
                    <a :href="baseUrl+concept_protocol_record.protocol_document" target="_blank">
                        <feather-icon
                            icon="EyeIcon"
                            class="cursor-pointer mt-0 mt-md-2"
                            size="25"
                        />
                    </a>
                </b-col>
                <b-col sm="1">
                    <b-button
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index, concept_protocol_record.id)"
                    >
                        <feather-icon
                            icon="TrashIcon"
                            class="mr-25"
                        />
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="d-flex align-items-center justify-content-start" cols="12" md="12">
                    <b-button @click="addNewRow()">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                        />
                        <span>Afegir Concepte/Protocol</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-card>
</template>
<script>
import {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BFormRadio,
    BFormFile,
    BFormTextarea,
    BButton,
    BCard,
    BFormCheckbox
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {baseUrl} from "@/constants/app"
import helpers from "@/resources/helpers/helpers"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
    getterType: 'trading/getField',
    mutationType: 'trading/updateField',
})

export default {
    name: "ConceptsProtocols",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BButton,
        BCard,
        BFormCheckbox,

        vSelect,
        flatPickr,
    },
    data() {
        return {
            SpanishLocale,
            clients: [],
            baseUrl,
            helpers,
        }
    },
    computed: {
        ...mapState('trading', ['validation_trading']),
        ...mapFields({
            trading: 'trading',
        }),
    },
    methods: {
        addNewRow() {
            this.trading.concepts_protocols.push({
                scripture_document_file: null,
                scripture_document: null,
                concept: null,
                protocol_document_file: null,
                protocol_document: null,
                protocol: null,
            })
        },
        removeItem(index, recordId) {
            if (recordId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('trading/deleteTradingProtocol', recordId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Registre eliminat correctament', 'CheckIcon', 'success')
                            this.trading.concepts_protocols.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar registre', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.trading.concepts_protocols.splice(index, 1)
            }
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>