<template>
    <div>
        <b-row>
            <b-col sm="4">
                <b-form-group label="Client" label-for="value">
                    <v-select
                        v-model="trading.client_id"
                        :options="clients"
                        label="name"
                        :reduce="(option) => option.id"
                        :disabled="disabled"
                    >
                        <template #no-options="{ }">
                            No s'han pogut carregar els clients
                        </template>
                        <template slot="option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                    </v-select>
                    <small v-if="validation_trading.client_id" class="text-danger">
                        {{ validation_trading.client_id }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Categoria" label-for="value">
                    <v-select
                        v-model="trading.category"
                        :options="scripturesCategories"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_trading.category" class="text-danger">
                        {{ validation_trading.category }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="4" md="12" sm="12">
                <b-form-group label="Tipus d'escriptures" label-for="value">
                    <v-select
                        v-model="trading.scripture_type"
                        :options="scripturesTypesTrading"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_trading.scripture_type" class="text-danger">
                        {{ validation_trading.scripture_type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Es necessitarà factura" label-for="value">
                    <v-select
                        v-model="trading.invoice"
                        :options="boolean"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_trading.invoice" class="text-danger">
                        {{ validation_trading.invoice }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>

            <b-col lg="6" md="12" sm="12">
                <b-form-group label="Autorització a GAG de representació" label-for="minuta-notari">
                    <b-form-file
                        v-model="trading.authorization_gag_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="trading.authorization_gag_document" class="mr-2 ml-0">
                <a :href="baseUrl+trading.authorization_gag_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon
                    v-if="trading.authorization_gag_document || trading.authorization_gag_document_file"
                    icon="TrashIcon"
                    class="cursor-pointer ml-1 mt-md-2"
                    size="25"
                    @click="trading.authorization_gag_document_file = trading.authorization_gag_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <b-form-group label="Observacions internes" label-for="observations">
                    <b-form-textarea v-model="trading.internal_observations" placeholder="Observacions internes"
                                     :class="validation_trading.internal_observations ? 'is-invalid': ''"/>
                    <small v-if="validation_trading.internal_observations" class="text-danger">
                        {{ validation_trading.internal_observations }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Notari" label-for="document">
                    <b-form-input v-model="trading.notary" placeholder="Notari"/>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group label="Minuta notari" label-for="minuta-notari">
                    <b-form-file
                        v-model="trading.notary_bill_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="trading.notary_bill_document" class="mr-2 ml-0">
                <a :href="baseUrl+trading.notary_bill_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="trading.notary_bill_document || trading.notary_bill_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="trading.notary_bill_document_file = trading.notary_bill_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data signatura escriptura" label-for="value">
                    <flat-pickr
                        v-model="trading.signature_date"
                        class="form-control"
                        :class="validation_trading.signature_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data signatura escriptura"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_trading.signature_date" class="text-danger">
                        {{ validation_trading.signature_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group label="Escriptura" label-for="trading">
                    <b-form-file
                        v-model="trading.scripture_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="trading.scripture_document" class="mr-2 ml-0">
                <a :href="baseUrl+trading.scripture_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="trading.scripture_document || trading.scripture_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="trading.scripture_document_file = trading.scripture_document = null"
                />
            </b-col>
            <b-col sm="3">
                <b-form-group label="Protocol" label-for="protocol">
                    <b-form-input v-model="trading.protocol" placeholder="Protocol"
                                  :class="validation_trading.protocol ? 'is-invalid': ''"/>
                    <small v-if="validation_trading.protocol" class="text-danger">
                        {{ validation_trading.protocol }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data devengo" label-for="value">
                    <flat-pickr
                        v-model="trading.accrual_date"
                        class="form-control"
                        :class="validation_trading.accrual_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data devengo"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_trading.accrual_date" class="text-danger">
                        {{ validation_trading.accrual_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data limit" label-for="value">
                    <flat-pickr
                        v-model="trading.limit_date"
                        class="form-control"
                        :class="validation_trading.limit_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data limit"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_trading.limit_date" class="text-danger">
                        {{ validation_trading.limit_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data prescripció" label-for="prescripció">
                    <flat-pickr
                        v-model="trading.prescription_date"
                        class="form-control"
                        :class="validation_trading.prescription_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data prescripció"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_trading.prescription_date" class="text-danger">
                        {{ validation_trading.prescription_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Estat" label-for="value">
                    <select v-model="trading.status" class="form-group custom-select"
                            :class="'background-' + trading.status">-
                        <option v-for="status in scripturesStatus"
                                :key="status.value"
                                :selected="status.value === trading.status"
                                :value="status.value"> {{ status.name }}
                        </option>
                    </select>
                    <small v-if="validation_trading.status" class="text-danger">
                        {{ validation_trading.status }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <hr>
        <capital-gain-form/>
        <concepts-protocols-form/>
        <model036-form/>
        <model600-form/>
        <open-model-form/>
        <hr>
        <trading-records-form/>
        <properties-records-form/>
        <hr>
        <b-row>
            <b-col class="col-sm-3">
                <b-form-group label="Certificat digital" label-for="value">
                    <v-select
                        v-model="trading.digital_certificate_type"
                        :options="digitalCertificatesOptions"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_trading.digital_certificate_type" class="text-danger">
                        {{ validation_trading.digital_certificate_type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="trading.digital_certificate_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="trading.digital_certificate_document" class="mr-2 ml-0">
                <a :href="baseUrl+trading.digital_certificate_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="trading.digital_certificate_document || trading.digital_certificate_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="trading.digital_certificate_document_file = trading.digital_certificate_document = null "
                />
            </b-col>
            <b-col sm="3">
                <b-form-group label="Contrasenya" label-for="document">
                    <b-form-input v-model="trading.digital_certificate_password" placeholder="Contrasenya"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <b-form-group label="Observacions" label-for="observations">
                    <b-form-textarea v-model="trading.observations" placeholder="Observacions"
                                     :class="validation_trading.observations ? 'is-invalid': ''"/>
                    <small v-if="validation_trading.observations" class="text-danger">
                        {{ validation_trading.observations }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <invoices-form/>
    </div>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BRow
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {
    autonomousCommunities,
    digitalCertificatesOptions,
    scripturesCategories,
    scripturesStatus,
    scripturesTypes, scripturesTypesTrading
} from "@/constants/trading"
import {baseUrl} from "@/constants/app"
import model036Form from "@/views/clients/form/components/models/model036Form"
import model600Form from "@/views/clients/form/components/models/model600Form"
import openModelForm from "@/views/clients/form/components/models/modelOpenModelForm"
import invoicesForm from "@/views/trading/trading/components/invoicesForm"
import propertiesRecordsForm from "@/views/trading/trading/components/propertiesRecordsForm"
import conceptsProtocolsForm from "@/views/trading/trading/components/conceptsProtocolsForm"
import tradingRecordsForm from "@/views/trading/trading/components/tradingRecordsForm"
import capitalGainForm from "@/views/clients/form/components/models/capitalGainForm"
import {boolean} from "@/constants/constants"
import Swal from "sweetalert2"
import helpers from "@/resources/helpers/helpers"


const {mapFields} = createHelpers({
    getterType: 'trading/getField',
    mutationType: 'trading/updateField',
})

export default {
    name: "FormTradingView",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BButton,
        BCard,
        BFormCheckbox,

        model036Form,
        model600Form,
        openModelForm,
        invoicesForm,
        propertiesRecordsForm,
        conceptsProtocolsForm,
        tradingRecordsForm,
        capitalGainForm,
        vSelect,
        flatPickr,
    },
    props: ['disabled'],
    data() {
        return {
            SpanishLocale,
            clients: [],

            scripturesTypesTrading,
            scripturesCategories,
            scripturesStatus,
            autonomousCommunities,
            digitalCertificatesOptions,

            boolean,
            baseUrl,
            helpers
        }
    },
    computed: {
        ...mapState('trading', ['validation_trading']),
        ...mapFields({
            trading: 'trading',
            client: 'client',
        }),
    },
    watch: {
        'trading.signature_date': function (changes) {
            if (changes) {
                const partsDate = changes.split('-')
                const date = new Date(partsDate[2], partsDate[1] - 1, partsDate[0])
                date.setMonth(date.getMonth() + 1)
                this.trading.limit_date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                this.trading.accrual_date = changes
            }
        },
        'trading.limit_date': function (changes) {
            if (changes) {
                const partsDate = changes.split('-')
                const date = new Date(partsDate[2], partsDate[1] - 1, partsDate[0])
                date.setFullYear(date.getFullYear() + 4)
                this.trading.prescription_date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
            }
        },
        'trading.client_id': function (client) {
            if (client) {
                this.getClientData(client)
            }
        },
    },
    beforeMount() {
        this.$store.commit('trading/RESET_STATE')
        this.$store.commit('client/RESET_STATE')
    },
    mounted() {
        this.getClients()
    },
    methods: {
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients', {
                id: this.$route.params.id,
                relations: ['responsible'],
            })
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        addNewRowModel(modelFlag, flag) {
            modelFlag.push({
                liquidation_date: null,
                signature_date: null,
                flag: flag,
            })
        },
        removeItemModel(modelFlag, index, modelDocumentId) {
            if (modelDocumentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('trading/deleteTradingModelRegister', modelDocumentId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            modelFlag.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                modelFlag.splice(index, 1)
            }
        },
        getClientData(client_id) {
            this.showLoading = true
            this.$store.dispatch('client/getClient', {
                id: client_id,
                relations: ['clientModels'],
            })
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },

    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

hr {
    display: block;
    width: 100%;
    height: 1px;
}

</style>