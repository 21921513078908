<template>
    <b-card title="Registres de mercantil" border-variant="primary" class="text-left mt-2">
        <b-col lg="12" md="12" sm="12">
            <b-row v-for="(trading_record, index) in trading.tradings_records"
                   :key="trading_record.id">
                <b-col sm="3">
                    <b-form-group label="Descripció" label-for="document">
                        <b-form-input v-model="trading_record.description" placeholder="Descripció"/>
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label="Document" label-for="document">
                        <b-form-file
                            v-model="trading_record.document_file"
                            placeholder="Seleccionar"
                            drop-placeholder="Seleccionar"
                            browse-text="Seleccionar"
                        />
                    </b-form-group>
                </b-col>
                <b-col v-if="trading_record.document" class="mr-2 ml-0">
                    <a :href="baseUrl+trading_record.document" target="_blank">
                        <feather-icon
                            icon="EyeIcon"
                            class="cursor-pointer mt-0 mt-md-2"
                            size="25"
                        />
                    </a>
                    <feather-icon v-if="trading_record.document || trading_record.document_file"
                                  icon="TrashIcon"
                                  class="cursor-pointer ml-1 mt-md-2"
                                  size="25"
                                  @click="trading_record.document_file = trading_record.document = null "
                    />
                </b-col>
                <b-col sm="2">
                    <b-form-group label="Data entrada" label-for="date-liquidation">
                        <flat-pickr
                            v-model="trading_record.date_entry"
                            class="form-control"
                            name="date"
                            placeholder="Data entrada"
                            :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label="Data sortida" label-for="date-liquidation">
                        <flat-pickr
                            v-model="trading_record.date_exit"
                            class="form-control"
                            name="date"
                            placeholder="Data sortida"
                            :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="1">
                    <b-button
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index, trading_record.id)"
                    >
                        <feather-icon
                            icon="TrashIcon"
                            class="mr-25"
                        />
                    </b-button>
                </b-col>
                <b-col sm="12">
                    <b-form-group label="Observacions" label-for="date-liquidation">
                        <b-form-textarea v-model="trading_record.observations" placeholder="Observacions"/>
                    </b-form-group>
                </b-col>
                <hr/>
            </b-row>

            <b-row>
                <b-col class="d-flex align-items-center justify-content-start" cols="12" md="12">
                    <b-button @click="addNewRow()">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                        />
                        <span>Afegir registre de mercantil</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-card>
</template>
<script>
import {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BFormRadio,
    BFormFile,
    BFormTextarea,
    BButton,
    BCard,
    BFormCheckbox
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {baseUrl} from "@/constants/app"
import helpers from "@/resources/helpers/helpers"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
    getterType: 'trading/getField',
    mutationType: 'trading/updateField',
})

export default {
    name: "Invoices",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BButton,
        BCard,
        BFormCheckbox,

        vSelect,
        flatPickr,
    },
    data() {
        return {
            SpanishLocale,
            clients: [],
            baseUrl,
            helpers,
        }
    },
    computed: {
        ...mapState('trading', ['validation_trading']),
        ...mapFields({
            trading: 'trading',
        }),
    },
    methods: {
        addNewRow() {
            this.trading.tradings_records.push({
                description: null,
                document: null,
                document_file: null,
                date_entry: null,
                date_exit: null,
                observations: null,
            })
        },
        removeItem(index, propertyId) {

            if (propertyId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('trading/deleteTradingRecord', propertyId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Registre eliminat correctament', 'CheckIcon', 'success')
                            this.trading.tradings_records.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar registre', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.trading.tradings_records.splice(index, 1)
            }
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>